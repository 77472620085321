<div class="container-fluid main">
  <div class="w-100">
    <div class="container-fluid">
      <div
        class="col-md-8 col-sm-12 col-12 p-0 heading pt-2"
        style="text-align: center"
      >
        <span
          style="display: flex; justify-content: start; align-items: flex-start"
          class="large backButton"
          (click)="switchView()"
        >
          <i class="fa fa-chevron-left" aria-hidden="true">
            <span class="small"> Back </span>
          </i>
        </span>
        <div style="text-align: center">
          <h3>{{ businessDetails?.businessName }}</h3>
        </div>
      </div>
      <div class="calenderbookvino">
        <div class="col-md-8 col-sm-12 col-12 p-0">
          <div class="info" style="text-align: center">
            <div
              class="col-sm-12 col-md-12 col-lg-12 mt-5"
              style="display: flex; justify-content: center"
            >
              <div class="form-check-inline">
                <button class="btn btn-primary" (click)="minus()">
                  <i class="fa fa-minus"></i>
                </button>
              </div>
              <div class="form-check-inline">
                <input
                  type="text"
                  class="form-control"
                  style="text-align: center"
                  [(ngModel)]="inputnumber"
                />
              </div>
              <div class="form-check-inline">
                <button class="btn btn-primary" (click)="plus()">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="bookButton mt-5 mb-3">
              <button
                type="button"
                class="btn btn-dark"
                (click)="gotobookings()"
              >
                Check Availability
              </button>
            </div>
          </div>

          <div
            style="display: none"
            class="calender col-12 col-md-12 col-sm-12 p-0"
          >
            <div
              class="input-group col-12 col-md-12 col-sm-12"
              style="display: flex; justify-content: center"
            >
              <input type="hidden" />
              <ngb-datepicker
                #dp
                id="dpt"
                [(ngModel)]="model"
                (dateSelect)="getSlotsByDate($event)"
                style="margin: 10px auto"
                [markDisabled]="isDisabled"
              >
              </ngb-datepicker>
            </div>
            <div
              class="bookingSummaryBookNow col-md-12"
              style="text-align: center; padding-bottom: 10px"
            >
              <div class="currentslots" style="display: none">
                <select
                  class="custom-select slotsDropdown"
                  aria-label="Default select example"
                >
                  <option *ngFor="let item of getSlots" value="{{ item.id }}">
                    {{ item?.entry }} - {{ item?.exit }}
                  </option>
                </select>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
                style="margin-top: 16px"
                (click)="bookNow()"
              >
                Go To Booking
              </button>
            </div>
          </div>

          <div style="display: none" class="bookingForm p-4">
            <form [formGroup]="bookingForm" (submit)="onSubmitHandler()">
              <h4 class="mb-4">Personal Details</h4>
              <div class="row mb-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="First Name"
                      class="form-control form-control-alternative"
                      formControlName="firstName"
                    />
                  </div>
                  <div
                    *ngIf="isSubmited && bookingForm.controls.firstName.errors"
                  >
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.firstName.errors"
                    >
                      Name Required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Last Name"
                      class="form-control form-control-alternative"
                      formControlName="lastName"
                    />
                  </div>
                  <div
                    *ngIf="isSubmited && bookingForm.controls.lastName.errors"
                  >
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.lastName.errors.required"
                    >
                      Last Name Required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Email"
                      class="form-control form-control-alternative"
                      formControlName="email"
                      style="text-transform: lowercase"
                    />
                  </div>
                  <div *ngIf="isSubmited && bookingForm.controls.email.errors">
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.email.errors.required"
                    >
                      Email Required
                    </div>
                    <div
                      *ngIf="
                        isSubmited && bookingForm.controls.email.errors.pattern
                      "
                    >
                      <div
                        class="text-danger"
                        *ngIf="
                          bookingForm.controls.email.errors.pattern
                            .requiredPattern
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Phone Number"
                      class="form-control form-control-alternative"
                      id="phoneNumberValidation"
                      formControlName="phoneNumber"
                      (keyup)="checkIfNumber($event.target.value)"
                    />
                  </div>
                  <div
                    *ngIf="
                      isSubmited && bookingForm.controls.phoneNumber.errors
                    "
                  >
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.phoneNumber.errors.required"
                    >
                      Phone Number Required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" style="font-weight: 500; font-size: 16px"
                      >Adults</label
                    >
                    <select
                      formControlName="adult"
                      (change)="setAdultTotal($event.target.value)"
                      class="custom-select form-control-alternative mb-3"
                      aria-placeholder="Category"
                    >
                      <option [value]="item" *ngFor="let item of foo">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="isSubmited && bookingForm.controls.adult.errors">
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.adult.errors.required"
                    >
                      Please select Adult
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" style="font-weight: 500; font-size: 16px"
                      >Children</label
                    >
                    <select
                      formControlName="children"
                      (change)="setChildTotal($event.target.value)"
                      class="custom-select form-control-alternative mb-3"
                      aria-placeholder="Category"
                    >
                      <option [value]="item" *ngFor="let item of foo">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div
                    *ngIf="isSubmited && bookingForm.controls.children.errors"
                  >
                    <div
                      class="text-danger"
                      *ngIf="bookingForm.controls.children.errors.required"
                    >
                      Please select Children
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <span> Total: {{ total }} </span>
              </div>
              <div
                class="bookingSummaryBookNow col-md-12"
                style="text-align: center; padding-bottom: 10px"
              >
                <button class="btn btn-primary" type="submit">Book Now</button>
              </div>
            </form>
          </div>

          <div class="winery" *ngIf="backButton == 1 || backButton == 3">
            <div class="row">
              <div class="col-md-12">
                <h4>{{ "Winerie-Details_About_the_winery" | translate }}</h4>
                <h6
                  style="
                    color: #222222 !important;
                    font-family: Circular, -apple-system, BlinkMacSystemFont,
                      Roboto, Helvetica Neue, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                  "
                ></h6>

                <div
                  style="
                    color: #222222 !important;
                    font-family: Circular, -apple-system, BlinkMacSystemFont,
                      Roboto, Helvetica Neue, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                  "
                >
                  {{ businessDetails?.details }}
                </div>
              </div>
              <br />

              <div class="col-md-12 mt-5">
                <h4>{{ "Winerie-Details_About_the_tasting" | translate }}</h4>
                <h6
                  style="
                    color: #222222 !important;
                    font-family: Circular, -apple-system, BlinkMacSystemFont,
                      Roboto, Helvetica Neue, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                  "
                >
                  <div
                    style="
                      color: #222222 !important;
                      font-family: Circular, -apple-system, BlinkMacSystemFont,
                        Roboto, Helvetica Neue, sans-serif !important;
                      font-weight: 400 !important;
                      font-size: 16px !important;
                      line-height: 24px !important;
                    "
                  >
                    {{ businessDetails?.tastingDetails }}
                  </div>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-8 col-lg-4 col-sm-12 col-xs-12 col-12 p-0"
          style="overflow-y: scroll; height: 737px"
        >
          <div class="carousel">
            <a class="prev"
              ><i class="fa fa-caret-up" aria-hidden="true"></i
            ></a>
            <div class="window">
              <div class="item" *ngFor="let list of backgroundImage">
                <img
                  class="img-fluid img-thumbnail"
                  src="{{ list }}"
                  alt="#Img desc#"
                  loading="lazy"
                />
              </div>
            </div>
            <a class="next"
              ><i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
