import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url;
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
    
      return this.checkUserLogin(route, url);
    }

    
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {

    if (this.authenticationService.isLoggedIn()) {
      const userRole = this.authenticationService.getRole();
      
      if (route.data.role != null) {
        if (!route.data.role.includes(localStorage.getItem("Role"))) {
          this.router.navigate(["/"]);
          return false;
        }
      }
      return true;
    }

    this.router.navigate(["/"]);
    return false;
  }
}
