import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { apiRoutes } from "../routes/apiRouets";
import { environment } from "src/environments/environment";
import { User } from "../userService/userLoginModal";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { logInResponse } from "../responseMessages/response";
import { userType } from "../../Enums/userType";
import Swal from "sweetalert2";
@Injectable({ providedIn: "root" })
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<User>;
  roleAs: string;
  isLogin: boolean;

  constructor(
    private http: HttpClient,
    private route: Router,
    public toastr: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async login(loginObj: any) {
    return await this.http
      .post<any>(environment.API_URL + apiRoutes.login, loginObj)
      .toPromise()
      .then(async (user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        let role = await this.getRole();
        localStorage.setItem("Role", role);
        this.currentUserSubject.next(user);
        this.roleAs == "SuperAdmin"
          ? this.route.navigate(["/admin/dashboard"])
          : this.route.navigate(["/admin/profile"]);
        return user;
      })
      .catch((error) => {
        this.toastr.error(logInResponse.login_error, "Login Error");
      });
  }

  register(regObj: any) {
    return this.http
      .post<any>(environment.API_URL + apiRoutes.register, regObj)
      .subscribe(
        (user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.route.navigate(["session/login"]);
          Swal.fire("Registration sucessfully ", "", "success");
          return user;
        },
        (error) => {
          this.toastr.error(error.error.errors.email, "Email already taken", {
            timeOut: 5000,
          });
        }
      );
  }

  ownerRegister(ownerObject: any) {
    return this.http
      .post<any>(environment.API_URL + apiRoutes.ownerRegister, ownerObject)
      .subscribe(
        (user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.route.navigate(["session/login"]);
          Swal.fire("Registration sucessfully as Winery Owner ", "", "success");
          return user;
        },
        (error) => {
          this.toastr.error(error.error.errors.email, "Email already taken ", {
            timeOut: 5000,
          });
        }
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("Role");
    localStorage.removeItem('CookieAccepted');
  }

  isLoggedIn() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let role = user?.data?.userType;
    this.roleAs = userType[role];
    if (user != null) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    return this.isLogin;
  }

  async getRole() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let role = user?.data?.userType;
    this.roleAs = userType[role];
    return this.roleAs;
  }
}
