export const environment = {
  production: true,
  // API_URLL: "http://192.168.1.29:8000/",
  //     API_URL: "https://ec2-13-37-92-2.eu-west-3.compute.amazonaws.com:4437/",
  //   API_URL: "http://localhost:5262/",
    API_URL: "https://bookvino.com/",
      //API_URL: "http://35.180.114.50:8080/",
  // API_URL: "http://35.154.186.154:4437/",
  DummyImage : "./Uploads/733148ab-9331-49a4-81ef-4e320eba9ab8.jpg",
  GridImage : "./Uploads/b2f49e8f-21d7-4814-9c00-b5d2460a2625.jpg",
  ImageGrid : "./Uploads/401c99f2-47b5-4a61-8f6d-b377b197a970.jpg"
};
