import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common/common.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";
  lang: any = "en";

  constructor(
    public translate: TranslateService,
    private common: CommonService,
    private router: Router
  ) {
    if (window.location.href.includes("/da")) {
      this.lang = "da";
      localStorage.setItem("langInfo", "da");
      localStorage.setItem("baseURL", "/");
    } else {
      this.lang = "en";
      localStorage.setItem("langInfo", "en");
      localStorage.setItem("baseURL", "/");
    }

    this.common.languageChange.subscribe(
      (languageChange) => (this.lang = languageChange)
    );
    translate.addLangs(["en", "da"]);
    if (localStorage.getItem("langInfo") != null) {
      const data = localStorage.getItem("langInfo");
      translate.setDefaultLang(data);
      if (data === "en") {
        this.common.changeLanguage("da");
      } else {
        this.common.changeLanguage("en");
      }
    } else {
      translate.setDefaultLang("en");
      this.common.changeLanguage("da");
    }
  }
}
