import { Routes } from "@angular/router";

import { AdminPanelLayoutComponent } from "./layouts/adminPanel/AdminPanelLayout.component";
import { FrontendPanelLayoutComponent } from "./layouts/frontendPanel/FrontendPanel.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AuthGuard } from "./services/authGuard/auth.guard";
import { DirectBookingComponent } from "./direct-booking/direct-booking.component";
import { Confirmationpage2Component } from "./confirmationpage2/confirmationpage2.component";
import { WinelocationsComponent } from "./pages/winelocations/winelocations.component";
import { WinerylandingpageComponent } from "./pages/winerylandingpage/winerylandingpage.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    path: "",
    component: FrontendPanelLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./listing/listing.module").then((m) => m.ListingModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "admin",
    component: AdminPanelLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./adminPages/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "session",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./session/session.module").then((m) => m.SessionModule),
      },
    ],
  },
  {
    path: "booknow",
    component: DirectBookingComponent,
  },
  {
    path: "confirmationpage",
    component: Confirmationpage2Component,
  },
{
  path: "Winerylocation",
  component: WinelocationsComponent,
},
{
  path: "Winerylandingpage/:Id/:name",
  component: WinerylandingpageComponent,
}
  
];
