<div class="wines-page">
  <div style="position: relative" class="main-image-background">
    <img
      src="../../../assets/images/winebackground.png"
      alt="about"
      width="100%"
      style="
        max-height: 600px;
        height: 600px;
        object-fit: cover;
        margin-top: 150px;
        z-index: 3;
      "
    />
    <div>
      <p style="text-align: center" class="centered">
        Discover all the world's wines location!
      </p>
      <div class="fs-content pt-5 px-5 searchsection">
        <!-- Search -->
        <section class="search">
          <div class="row">
            <div class="col-md-12">
              <form>
                <!-- Row With Forms -->
                <div class="row with-forms">
                  <!-- Main Search Input -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <select
                        (change)="setCountry($event.target.value)"
                        class="custom-select my-1 mr-sm-2 background"
                      >
                        <option
                          style="background-color: #1aae6f"
                          [ngValue]="null"
                        >
                          {{ "Select_Country" | translate }}
                        </option>
                        <!-- <option *ngIf="countryValue != null" [ngValue]="null">
                          {{ countryValue }}
                        </option> -->
                        <option
                          style="background-color: #1aae6f"
                          *ngFor="let country of allCountries"
                          [attr.selected]="
                            country.id == countryValue ? true : null
                          "
                          [value]="country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Main Search Input -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <select
                        class="custom-select my-1 mr-sm-2 background"
                        (change)="setRegion($event.target.value)"
                      >
                        <option
                          style="background-color: #1aae6f"
                          [value]="null"
                          selected
                        >
                          {{ "Any_Region" | translate }}
                        </option>
                        <option
                          style="background-color: #1aae6f"
                          *ngFor="let category of allStates"
                          [attr.selected]="
                            category.id == stateValue ? true : null
                          "
                          [value]="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <select
                        class="custom-select my-1 mr-sm-2 background"
                        (change)="setSubRegion($event.target.value)"
                      >
                        <option
                          style="background-color: #1aae6f"
                          [value]="null"
                          selected
                        >
                          {{ "Winaries_sub_region" | translate }}
                        </option>
                        <option
                          style="background-color: #1aae6f"
                          *ngFor="let city of allCity"
                          [attr.selected]="city.id == cityValue ? true : null"
                          [value]="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn main-search-btn btn-radius btn-lg btn-primary text-white"
                      type="submit"
                      (click)="searchHandler()"
                    >
                      {{ "Search" | translate }}
                    </button>
                  </div>
                </div>
                <!-- Row With Forms / End -->
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
<div class="main-container-ab">
  <p>
    What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book. It has survived not only What is
    Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book. It has survived not only What is
    Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book. It has survived not only .
  </p>
</div>
<div class="Main-Heading">
  <h5>Selected location</h5>
</div>
<div class="sub-Main-Heading">
  <p>
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat eum verit
  </p>
</div>
<div class="container">
  <div class="dashboard-content container">
    <div class="col-lg-12 col-md-12">
      <div class="dashboard-list-box mb-5">
        <ul class="row border-grid">
          <li class="col-sm-4 col-md-4 col-lg-4" *ngFor="let list of allPages">
            <div
              id="new-halfCard"
              [routerLink]="['/Winerylandingpage', list.pageId, list.pageName]"
              class="col-lg-12 col-md-12 col-xl-12 grid-layout-list mb-4 ng-star-inserted cards"
            >
              <div class="listing-item-container" style="position: relative">
                <div class="listing-item" tabindex="0">
                  <img
                    alt=""
                    style="
                      height: 270px !important;
                      width: 100%;
                      cursor: pointer;
                    "
                    src="{{ list.backgroundImage }}"
                  /><span
                    class="badge badge-pill badge-primary text-uppercase"
                  ></span>
                  <div class="listing-item-content">
                    <h3>
                      <a style="color: #fff !important">
                        {{ list.pageName }}
                      </a>
                    </h3>
                    <span
                      ><small style="color: #fff !important; font-size: 15px"
                        >In La Morra, surrounded by hil.....</small
                      ></span
                    >
                    <div class="btnLeft">
                      <span
                        ><i class="fa fa-map-marker"></i
                        ><strong class="iconText mb-2">{{
                          list.stateName
                        }}</strong></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div style="display: flex; justify-content: center; align-items: center">
  <button
  class="loadmorebtn"
    style="
    color: white;
    background-color: #1aae6f;
    border-radius: 25px;
    border-bottom: #1aae6f;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: none;
    padding: 7px;
    "
    type="button"
    (click)="loadMorePages()"
  >
    Explore more
  </button>
</div>
