import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;
import { AuthService } from "src/app/services/authGuard/auth.service";

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./AdminSidebar.component.html",
  styleUrls: ["./AdminSidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AdminSidebarComponent implements OnInit {
  userType: any;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.userType = JSON.parse(localStorage.getItem("currentUser"));
    if (window.location.href.includes("admin/profile")) {
      document.getElementById("myProfile").click();
    }
  }

  ngAfterViewInit() {
    if (this.userType.data.userType == 1) {
      if (window.location.href.includes("admin/bookings")) {
        document.getElementById("myOrders2").click();
      }
    } else {
      if (window.location.href.includes("admin/bookings")) {
        document.getElementById("myOrders").click();
      }
    }
  }

  toggleMenu() {
    if ($("app-admin-panel").hasClass("sidebar-in")) {
      $("app-admin-panel").removeClass("sidebar-in");
    } else {
      $("app-admin-panel").addClass("sidebar-in");
    }
  }
  logout() {
    this.authService.logout();
  }
}
