import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";

import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { AdminPanelLayoutComponent } from "./layouts/adminPanel/AdminPanelLayout.component";
import { FrontendPanelLayoutComponent } from "./layouts/frontendPanel/FrontendPanel.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

import { HeaderComponent } from "./core/Header/Header.component";
import { FooterComponent } from "./core/Footer/Footer.component";
import { MenuComponent } from "./core/Menu/Menu.component";
import { SignInComponent } from "./core/SignInPopup/SignIn.component";

import { AdminHeaderComponent } from "./core/AdminHeader/AdminHeader.component";
import { AdminSidebarComponent } from "./core/AdminSidebar/AdminSidebar.component";

import { MenuItems } from "./core/Menu/menu-items";
import { AdminMenuItems } from "./core/AdminHeader/admin-menu-items";
import { TokenInterceptor } from "../app/services/interceptor/tokenInterceptor";
import { AuthGuard } from "../app/services/authGuard/auth.guard";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { apiRoutes } from "./services/routes/apiRouets";
import { environment } from "src/environments/environment";
import { AgmCoreModule } from "@agm/core";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DragulaModule } from "ng2-dragula";
// import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { SweetAlert2 } from "sweetalert2/dist/sweetalert2";
import { NgApexchartsModule } from "ng-apexcharts";
import { DirectBookingComponent } from "./direct-booking/direct-booking.component";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { Confirmationpage2Component } from './confirmationpage2/confirmationpage2.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SlickCarouselModule } from "ngx-slick-carousel";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: environment.API_URL + apiRoutes.UploadFile,
  maxFilesize: 5000,
  acceptedFiles:
    "image/jpg,image/png,image/jpeg,image/apng,image/avif,image/gif,image/svg,image/webp,image/bmp,image/tif,image/tiff/*",
};

@NgModule({
  declarations: [
    AppComponent,
    AdminPanelLayoutComponent,
    FrontendPanelLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SignInComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    DirectBookingComponent,
    Confirmationpage2Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DropzoneModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: "enabled" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
    }),
    NgbModule,
    NgxDaterangepickerMd.forRoot(),
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBnXlLUPtQqHdl3XUjS1QmTHi8swCkP12E",
      libraries: ["geometry", "places"],
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DragulaModule.forRoot(),
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MenuItems,
    AdminMenuItems,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy,
    // },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule, DragulaModule],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
