<nav id="navigation" class="style-1">
  <ul id="responsive">
    <li
      *ngFor="let menuitem of menuItems.getAll()"
      [ngClass]="{ 'open-menu': selectedMenu == menuitem.name }"
    >
      <a
        class="current"
        style="font-weight: bold"
        [routerLink]="[baseURL, menuitem.state]"
        *ngIf="menuitem.type === 'link'"
        >{{ menuitem.name | translate }}</a
      >
      <a
        class="current"
        *ngIf="menuitem.type === 'sub'"
        (click)="menuClick(menuitem.name)"
        >{{ menuitem.name | translate }}</a
      >
      <ul *ngIf="menuitem.type === 'sub'">
        <li
          *ngFor="let childitem of menuitem.children"
          [ngClass]="{ 'open-menu': selectedSubMenu == childitem.name }"
        >
          <a
            [routerLink]="['/', menuitem.state, childitem.state]"
            *ngIf="childitem.type === 'link'"
            >{{ childitem.name | translate }}</a
          >
          <a
            *ngIf="childitem.type === 'sub'"
            (click)="subMenuClick(childitem.name)"
            >{{ childitem.name | translate }}</a
          >
          <ul *ngIf="childitem.type === 'sub'">
            <li *ngFor="let subchilditem of childitem.children">
              <a
                [routerLink]="[
                  '/',
                  menuitem.state,
                  childitem.state,
                  subchilditem.state
                ]"
                >{{ subchilditem.name | translate }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
