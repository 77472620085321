import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-confirmationpage2",
  templateUrl: "./confirmationpage2.component.html",
  styleUrls: ["./confirmationpage2.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class Confirmationpage2Component implements OnInit {
  value: any;
  currentUrl: any;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.value = localStorage.getItem("businessId");
    this.currentUrl = window.location.origin + "/booknow" + "?Id=" + this.value;
  }
  goToBack() {
    // this._router.navigate([` ${this.currentUrl}${this.value}`]);
    window.location.href = this.currentUrl;
  }
}
