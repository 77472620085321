<div id="footer" class="sticky-footer bg-primary">
  <!-- Main -->
  <div class="container">
    <div class="row pt-4 pb-4">
      <div class="col-md-3 col-sm-6">
        <img class="footer-logo" src="assets/images/logo-footer.png" alt="" />
        <br /><br />
        <p class="text-white copyright">
          {{ "Footer_Copy_Right" | translate }}
        </p>
        <!-- <div class="text-widget text-white">
               <span class="text-white">12345 Little Lonsdale St, Melbourne</span> <br>
               Phone: <span class="text-white">(123) 123-456 </span><br>
               E-Mail:<span class="text-white"> <a href="#" class="text-white">office@example.com</a> </span><br>
            </div> -->
      </div>
      <div class="col-md-3 col-sm-6">
        <h5 class="header">{{ "Footer_Quick_Links" | translate }}</h5>
        <hr class="footer-line" />
        <hr class="footer-small-line" />
        <ul class="footer-links">
          <li>
            <a class="text-white" routerLink="/about-us">{{
              "Footer_About" | translate
            }}</a>
          </li>
          <li>
            <a class="text-white" routerLink="/contact-us">{{
              "Footer_Contact" | translate
            }}</a>
          </li>
          <li>
            <a class="text-white" routerLink="/privacy">{{
              "Footer_Privacy_Policy" | translate
            }}</a>
          </li>
          <li>
            <a class="text-white" routerLink="/terms">{{
              "Footer_Terms" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-12">
        <h5 class="header">{{ "Footer_Wineries" | translate }}</h5>
        <hr class="footer-line" />
        <hr class="footer-small-line" />
        <ul class="footer-links">
          <li *ngIf ="IsLoggedIn">
            <a class="text-white" routerLink="/session/winery-signup">{{
              "Footer_Sign_Up" | translate
            }}</a>
          </li>
          <li *ngIf="IsLoggedIn">
            <a class="text-white" routerLink="/session/login">{{
              "Footer_Sign_In" | translate
            }}</a>
          </li>
          <li *ngIf="!IsLoggedIn">
            <a class="text-white" routerLink="/admin/dashboard">{{
              "Footer_Dashboard" | translate
            }}</a>
          </li>
          <li>
            <a class="text-white" routerLink="/cases">{{
              "Footer_Cases" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-12">
        <h5 class="header">{{ "Footer_Follow" | translate }}</h5>
        <hr class="footer-line" />
        <hr class="footer-small-line" />
        <div class="mt-4">
          <a
            target="_blank"
            href="https://www.facebook.com/bookvino"
            class="btn btn-neutral btn-icon-only btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Like us"
          >
            <i class="fa fa-facebook-square"></i>
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/book.vino/"
            class="btn btn-neutral btn-icon-only btn-lg btn-round"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-instagram"></i>
          </a>
          <!-- <a
            target="_blank"
            href="https://twitter.com/"
            class="btn btn-neutral btn-icon-only btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-twitter"></i>
          </a> -->
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCKmGBtyR3w7drge9NFIb4MA"
            class="btn btn-neutral btn-icon-only btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Star on Github"
          >
            <i class="fa fa-youtube-play"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pop-up">
  <div class="pop_up" *ngIf="isShow">
    <div class="pop_up_icon">
      <img src="assets/images/popup-icon.png" alt="" />
    </div>
    <div class="pop_up_content">
      <h5>{{ "Cookie_Your_Privacy" | translate }}</h5>
      <p>
        {{ "Cookie_Description_One" | translate }} "{{
          "Cookie_Description_Two" | translate
        }}",
        {{ "Cookie_Description_Three" | translate }}

        <a href="">{{ "Cookie_Policy" | translate }}</a>
      </p>
    </div>
    <div class="pop_up_btn">
      <button
        class="btn btn-lg btn-primary text-white popup_btn"
        (click)="toggleDisplay()"
      >
        {{ "Cookie_Accept" | translate }}
      </button>
      <!-- <button class="btn  btn-lg btn-primary text-white popup_btn">Customize settings </button> -->
    </div>
  </div>
</div>
  