import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MenuItems } from "./menu-items";

declare var $: any;
@Component({
  selector: "app-menu",
  templateUrl: "./Menu.component.html",
  styleUrls: ["./Menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit {
  baseURL: String = "";
  selectedMenu: any = null;
  selectedSubMenu: any = null;
  constructor(public menuItems: MenuItems, private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url == "/admin") {
          if (localStorage.getItem("currentUser") === null) {
            this.router.navigate(["session/login"]);
          } else {
            this.router.navigate(["/admin"]);
          }
        }
        $("#navbar_global").removeClass("show");
      }
    });
  }

  ngOnInit() {
  
    if (localStorage.getItem("baseURL") != null) {
      this.baseURL = localStorage.getItem("baseURL");
    }
    // if(this.baseURL.includes("/")){
    //    this.baseURL = "";
    // }
  }

  menuClick(value) {
    if (this.selectedMenu == value) {
      this.selectedMenu = null;
    } else {
      this.selectedMenu = value;
    }
  }

  subMenuClick(value) {
    if (this.selectedSubMenu == value) {
      this.selectedSubMenu = null;
    } else {
      this.selectedSubMenu = value;
    }
  }
}
