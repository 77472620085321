import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin-panel",
  styles: [
    ":host /deep/ .mat-sidenav-content {padding: 0;} .mat-sidenav-container {z-index: 1000}",
  ],
  templateUrl: "./AdminPanelLayout.component.html",
})
export class AdminPanelLayoutComponent implements OnInit {
  userType: any;

  constructor(private router: Router) {}
  ngOnInit() {

    this.userType = JSON.parse(localStorage.getItem("currentUser"));
    if (this.userType.data.userType == 3) {
      this.router.navigate(["/admin/profile"]);
    }
    if (this.userType.data.userType == 2) {
      this.router.navigate(["/admin/bookings"]);
    }
    if (this.userType.data.userType == 1) {
      this.router.navigate(["/admin/dashboard"]);
    }
  }
}
