<div class="main-wrapper" style="margin-top: 123px; margin-left: -4px">
  <!-- <title-bar title="Contact Us" subtitle="Explore your search places">
       </title-bar> -->
  <div class="contact-header">
    <div class="content">
      <div>
        <h1 class="text-center font-weight-bold">Thank-you For Booking</h1>
        <h1 class="text-center font-weight-bold">
          Please check your email for confirmation
        </h1>
      </div>

      <div class="contact-logo" style="text-align: center">
        <img
          src="https://bookvino.com:4437/Uploads/a23f5459-8d78-4c7d-aaf0-4d23352d66ef.png"
          alt="about"
          width="30%"
        />
      </div>
      <div class="text-center">
        <button (click)="goToBack()" class="btn btn-dark">OK</button>
      </div>
    </div>
  </div>
</div>
