export enum logInResponse {
  login_error = "User name or password is incorrect or User not exists.",
  success = "Successfully login.",
}

export enum signUpResponse {
  signUp_error = "User name or password is incorrect or User not exists.",
  signUp_success = "Admin Created Successfully.",
}

export enum contactUsResponse {
  contact_error = "Error occurred, Please try again",
  contact_success = "Thanks for Contact Us, will getback to you Soon.",
}

export enum enquiryResponse {
  enquiry_error = "Error occurred, Please try again",
  enquiry_success = "Thanks for Applying, will getback to you Soon.",
}

export enum bookingResponse {
  booking_error = "Error occurred, Please try again",
  booking_success = "Booked Successfully.",
}

export enum updateProfileResponse {
  profile_error = "Error occurred, Please try again",
  profile_success = "Profile Updated Successfully.",
}

export enum updateBusinessResponse {
  update_business_error = "Error occurred, Please try again",
  update_business_success = "Business Updated Successfully.",
}

export enum updateSlotsResponse {
  slots_update_error = "Error occurred, Please try again",
  slots_update_success = "Booking Slots Updated Successfully.",
}

export enum createBussinessResponse {
  create_bussiness_error = "Error occurred, Please try again",
  create_bussiness_success = "Bussiness Created Successfully.",
}
export enum deleteBussinessResponse {
  delete_bussiness_error = "Error occurred, Please try again",
  delete_bussiness_success = "Bussiness Deleted Successfully.",
}
export enum deletepageResponse {
  delete_page_error = "Error occurred, Please try again",
  delete_page_success = "page Deleted Successfully.",
}


export enum changePasswordResponse {
  change_Password_error = "Passwords did not match, Please try again",
  change_Password_success = "Password Changed Successfully.",
}

export enum forgotPasswordResponse {
  forgot_Password_error = "Error occurred, Please try again",
  forgot_Password_success = "A new Password has been sent to your Registered Email.",
}

export enum addCountryResponse {
  add_country_error = "Error occurred, Please try again",
  add_country_success = "Country Added Successfully.",
}

export enum delCountryResponse {
  del_country_error = "Error occurred, Please try again",
  del_country_success = "Country Deleted Successfully.",
}

export enum addStateResponse {
  add_state_error = "Error occurred, Please try again",
  add_state_success = "Region Added Successfully.",
}

export enum delStateResponse {
  del_state_error = "Error occurred, Please try again",
  del_state_success = "Region Deleted Successfully.",
}

export enum addCityResponse {
  add_city_error = "Error occurred, Please try again",
  add_city_success = "Sub-Region Added Successfully.",
}

export enum delCityResponse {
  del_city_error = "Error occurred, Please try again",
  del_city_success = "Sub-Region Deleted Successfully.",
}

export enum delUserResponse {
  del_user_error = "Error occurred, Please try again",
  del_user_success = "User Deleted Successfully.",
}

export enum delLangResponse {
  del_Lang_error = "Error occurred, Please try again",
  del_Lang_success = "Language Deleted Successfully.",
}

export enum delAmentyResponse {
  del_Amenty_error = "Error occurred, Please try again",
  del_Amenty_success = "Amenity Deleted Successfully.",
}

export enum addLangResponse {
  add_Lang_error = "Error occurred, Please try again",
  add_Lang_success = "Language Added Successfully.",
}

export enum addAmentyResponse {
  add_Amenty_error = "Error occurred, Please try again",
  add_Amenty_success = "Amenity Added Successfully.",
}

export enum addSlotsResponse {
  add_Slots_error = "Error occurred, Please try again",
  add_Slots_success = "Slots Booked Successfully.",
}

export enum editAdminResponse {
  edit_Admin_error = "Error occurred, Please try again",
  edit_Admin_success = "Admin Profile Updated Successfully.",
}

export enum editCustomerResponse {
  edit_Customer_error = "Error occurred, Please try again",
  edit_Customer_success = "Customer Profile Updated Successfully.",
}

export enum deleteCustomerResponse {
  del_Customer_error = "Error occurred, Please try again",
  del_Customer_success = "Customer Deleted Successfully.",
}

export enum businessStatusResponse {
  business_status_error = "Error occurred, Please try again",
  business_status_success = "Status of Business Updated Successfully.",
}

export enum bookingStatusResponse {
  booking_status_error = "Error occurred, Please try again",
  booking_status_success = "Status of Booking Updated Successfully.",
}

export enum featureStatusResponse {
  feature_status_error = "Error occurred, Please try again",
  feature_status_success = "Business Feature Status Updated Successfully.",
}

export enum editCountryResponse {
  edit_Country_error = "Error occurred, Please try again",
  edit_Country_success = "Country Updated Successfully.",
}

export enum editStateResponse {
  edit_State_error = "Error occurred, Please try again",
  edit_State_success = "Region Updated Successfully.",
}

export enum editCityResponse {
  edit_City_error = "Error occurred, Please try again",
  edit_City_success = "Sub-Region Updated Successfully.",
}

export enum editAmenityResponse {
  edit_Amenity_error = "Error occurred, Please try again",
  edit_Amenity_success = "Amenity Updated Successfully.",
}

export enum editLanguageResponse {
  edit_Language_error = "Error occurred, Please try again",
  edit_Language_success = "Language Updated Successfully.",
}

export enum checkSlotsResponse {
  slots_error = "Please Fill All Fields",
}
