export enum apiRoutes {
  "login" = "api/Auth/SignIn",
  "register" = "api/Auth/Register",
  "ownerRegister" = "api/Auth/RegisterWineryOwner",
  "ChangePassword" = "api/Auth/ChangePassword",
  "ForgotPassword" = "api/Auth/ForgotPassword",
  "GetBusinessById" = "api/Business/GetBusinessById",
  "GetAllBusiness" = "api/Business/GetAll",
  "GetAllCatagories" = "api/Catagories/GetAll",
  "GetAllCountries" = "api/Location/GetAllCountries",
  "ContactUs" = "api/ContactUs/ContactUsCreate",
  "EnquiryCreate" = "api/ContactUs/EnquiryCreate",
  "CreateBooking" = "api/Booking/CreateBooking",
  "GetAllBookings" = "api/Booking/GetAllBookings",
  "AcceptDeclineBooking" = "api/Booking/AcceptDeclineBooking",
  "CheckAvailability" = "api/Booking/CheckAvailability",
  "UploadFile" = "api/Booking/UploadFile",
  "DeleteBooking" = "api/Booking/DeleteBooking",
  "DeleteBusiness" = "api/Business/DeleteBusiness",
  "UpdateUserProfile" = "api/User/UpdateUserProfile",
  "UpdateUserById" = "api/User/UpdateUserById",
  "DeleteUser" = "api/User/DeleteUser",
  "GetAllAdmins" = "api/User/GetAllUserByUserType",
  "UploadPhoto" = "api/Booking/UploadFile",
  "GetUserProfile" = "api/User/GetUserProfile",
  "GetUserById" = "api/User/GetUserById",
  "GetAllStates" = "api/Location/GetAllStates",
  "GetAllCities" = "api/Location/GetAllCities",
  "GetAllLanguages" = "api/Business/GetAllLanguages",
  "GetAllAmenities" = "api/Business/GetAmentiesList",
  "AddAmenties" = "api/Business/AddAmenties",
  "CreateBusiness" = "api/Business/CreateBusiness",
  "ContactUsAll" = "api/ContactUs/GetAll",
  "GetTotalFeeds" = "api/Review/GetTotalFeeds",
  "AddCountry" = "api/Location/AddCountry",
  "AddLanguage" = "api/Business/AddLanguage",
  "DeleteCountry" = "api/Location/DeleteCountry",
  "AddState" = "api/Location/AddState",
  "UpdateCountry" = "api/Location/UpdateCountry",
  "UpdateState" = "api/Location/UpdateState",
  "UpdateCity" = "api/Location/UpdateCity",
  "UpdateLanguages" = "api/Business/UpdateLanguages",
  "UpdateAmenties" = "api/Business/UpdateAmenties",
  "DeleteState" = "api/Location/DeleteState",
  "AddCity" = "api/Location/AddCity",
  "DeleteCity" = "api/Location/DeleteCity",
  "GetAllStateCity" = "api/Location/GetAllStateCity",
  "AddBusinessTimeSlots" = "api/Business/AddBusinessTimeSlots",
  "DeleteBusinessLanguage" = "api/Business/DeleteLanguage",
  "DeleteBusinessAmeneties" = "api/Business/DeleteBusinessAmeneties",
  "GetBusinessTimeSlotsById" = "api/Business/GetBusinessTimeSlotsById",
  "UpdateBusiness" = "api/Business/UpdateBusiness",
  "UpdateBusinessTimeSlots" = "api/Business/UpdateBusinessTimeSlots",
  "AcceptRejectBusiness" = "api/Business/AcceptRejectBusiness",
  "IsFeaturedBusiness" = "api/Business/IsFeaturedBusiness",
  "DeleteBusinessTimeSlot" = "api/Business/DeleteBusinessTimeSlot",
  "GetCountryById" = "api/Location/GetCountryById",
  "GetStateById" = "api/Location/GetStateById",
  "GetCityById" = "api/Location/GetCityById",
  "GetAmenityById" = "api/User/UpdateUserById",
  "GetLanguageById" = "api/User/UpdateUserById",
  "AddRemoveFromFav" = "api/Business/FavouriteWinery",
  "CheckUser" = "api/User/SearchByEmail",
  "GetBusinessReports" = "api/Business/GetBusinessReports",
  "GetBookingsReports" = "api/Business/GetBookingsReports",
  "GetUserBookingReports" = "api/Business/GetUserBookingReports",
  "WineryReportsChart" = "api/Booking/WineryReportsChart",
  "WineryRevenueChart" = "api/Booking/WineryRevenueChart",
  "PublicBusiness" = "api/Business/PublicBusiness",
  "CreatePage" =  "api/PageBuilder/CreatePage",
  "UpdatePage" = "api/PageBuilder/UpdatePage",
  "GetAllPages" =  "api/PageBuilder/GetAllPages",
  "DeletePage" = "api/PageBuilder/DeletePage",
  "GetPageById"= "api/PageBuilder/GetPageById",
  "CreateBasicBusiness"="api/Business/CreateBasicBusiness",
  "CreateBusinessLocation"="api/Business/CreateBusinessLocation",
  "UpdateBasicBusiness"="api/Business/UpdateBasicBusiness",
  "UpdateBusinessAmenties"="api/Business/UpdateBusinessAmenties",
  "UpdateBusinessLocation"="api/Business/UpdateBusinessLocation",
  "UpdateBusinessOpenings"="api/Business/UpdateBusinessOpenings",
  "UpdateBusinessLanguage"="api/Business/UpdateBusinessLanguage",
  "UpdateBusinessPrimary"="api/Business/UpdateBusinessPrimary",
  "UpdateBusinessImages"="api/Business/UpdateBusinessImages",
  "UpdateBusinessSocial"="api/Business/UpdateBusinessSocial",
}
