<header class="header-global fixed-top">
  <div id="topnav">
    <div class="container">
      <div class="row pb-3 pt-3">
        <div class="col-md-6 col-sm-6 topnav-left col-7 pl-3">
          
          <span> <i class="fa fa-info-circle"></i> Book your next wine tasting experience here</span>
          <!-- <span> <i class="fa fa-phone"></i> +45 29875722 </span> -->
        </div>
        <!-- <div class="col-md-6 col-sm-8 topnav-center col-7">
          <span>
            <i class="fa fa-envelope-o mr-1"></i> booking@bookvino.com
          </span>
        </div> -->
        <div class="col-md-3 col-sm-12 topnav-right col-5">
          <div style="float: right">
            <a
              target="_blank"
              href="https://www.facebook.com/bookvino"
              data-toggle="tooltip"
              class="btn-icon"
              data-original-title="Like us"
            >
              <i class="fa fa-facebook-square"></i>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/book.vino/"
              class="btn-icon"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-instagram"></i>
            </a>
            <!-- <a target="_blank" href="https://twitter.com/" class="btn-icon" data-toggle="tooltip"
                      data-original-title="Follow us">
                      <i class="fa fa-twitter"></i>
                   </a> -->
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCKmGBtyR3w7drge9NFIb4MA"
              class="btn-icon"
              data-toggle="tooltip"
              data-original-title="Star on Github"
            >
              <i class="fa fa-youtube-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav
    id="navbar-main"
    class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom"
  >
    <div class="container pb-2 pt-1">
      <div id="logo">
        <a [routerLink]="['']"
          ><img src="assets/images/logo-white.png" alt=""
        /></a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar_global"
        aria-controls="navbar_global"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar_global">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <div id="logo">
                <a [routerLink]="['']"
                  ><img
                    class="img-fluid"
                    src="assets/images/logo-white.png"
                    width="54"
                    height="25"
                    alt=""
                /></a>
              </div>
            </div>
            <div class="col-6 collapse-close">
              <button
                type=" button"
                class="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <div
          class="navbar-nav navbar-nav-hover align-items-lg-center"
          style="width: 100%; display: block; margin-left: 40px"
        >
          <ul class="m-0 p-0">
            <app-menu></app-menu>
          </ul>
          <div class="header-widget" style="float: right; cursor: pointer">
            <!-- <img src="assets/images/uk.png" style="height: 28px;padding-top: 2px;margin-top: 2px;"> -->
            <span class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbar-primary_dropdown_1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><img src="assets/images/uk.png" alt=""
              /></a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbar-primary_dropdown_1"
              >
                <a
                  class="dropdown-item"
                  [class.selected]="lang === 'it'"
                  (click)="changeLanguage(1)"
                  >English</a
                >
                <!-- <a class="dropdown-item" [class.selected]="lang === 'da'" (click)="changeLanguage(2)">Italian</a> -->
                <a
                  class="dropdown-item"
                  [class.selected]="lang === 'en'"
                  (click)="changeLanguage(3)"
                  >Danish</a
                >
              </div>
            </span>
            <img
              *ngIf="userImage == null && userProfileData == null"
              class="pl-3"
              src="assets/images/user-profile.png"
              (click)="logIn()"
              style="
                height: 35px;
                padding-top: 2px;
                margin-top: 2px;
                margin-right: 105 px;
              "
            />

            <span class="nav-item dropdown">
              <a
                class="dropdown-toggle"
                id="navbar-primary_dropdown_2"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                *ngIf="userProfileData != null && userImage == null"
              >
                <img
                  *ngIf="userImage == null"
                  class="pl-3"
                  src="assets/images/user-profile.png"
                  style="
                    height: 35px;
                    padding-top: 2px;
                    margin-top: 2px;
                    margin-right: 105 px;
                  "
                />
                {{ userProfileData.firstName }} {{ userProfileData.lastName }}
              </a>

              <a
                class="dropdown-toggle"
                id="navbar-primary_dropdown_2"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                *ngIf="userImage != null && userProfileData != null"
              >
                <img
                  class="pl-3"
                  [src]="userImage"
                  style="
                    height: 35px;
                    padding-top: 2px;
                    margin-top: 2px;
                    margin-right: 105 px;
                  "
                />
                {{ userProfileData.firstName }} {{ userProfileData.lastName }}
              </a>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbar-primary_dropdown_2"
              >
                <a class="dropdown-item" [routerLink]="['/admin/profile']"
                  >Profile</a
                >
                <a class="dropdown-item" [routerLink]="['/admin/bookings']">{{
                  bookingOptions
                }}</a>
                <a class="dropdown-item" href="" (click)="logout()">Logout</a>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
