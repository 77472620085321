import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { apiRoutes } from "src/app/services/routes/apiRouets";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/internal/operators/filter";

@Component({
  selector: "app-winerylandingpage",
  templateUrl: "./winerylandingpage.component.html",
  styleUrls: ["./winerylandingpage.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WinerylandingpageComponent implements OnInit {
  baseURL: String = "";
  currentPageId: any;
  currentPageData: any;
  currentUrl: any;
  public tittle: string = "";
  countryId: any;
  stateId: any;
  cityId: any;
  allPages: any;

  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    private route: Router,
    private spinner: NgxSpinnerService,
    public router: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.currentUrl = environment.API_URL;
    this.currentPageId = this.router.snapshot.paramMap
      .get("Id")
      .substring(this.router.snapshot.paramMap.get("Id").indexOf("-") + 1);

    await this.getPageById(this.currentPageId);
   


 
  }


  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
        return this.getChild(activatedRoute.firstChild);  
    } else {  
        return activatedRoute;  
    }
}

  async getPageById(req: string) {
    this.spinner.show();
    let reqModel = {
      pageId: req,
    };
    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetPageById, reqModel)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.currentPageData = res.data;
          this.spinner.hide();

          let backgroundImage = document.getElementsByClassName(
            "mainImageModel"
          )[0] as HTMLImageElement;

          if (res.data.backgroundImage) {
            backgroundImage.src =
              environment.API_URL + res.data.backgroundImage;
          }
          // this.route.events.pipe(  
          //   filter(event => event instanceof NavigationEnd),  
          //  ).subscribe(() =>
          //   this.titleService.setTitle(this.currentPageData.pageName));
          let tittle = document.getElementsByClassName(
            "mainTitle"
          )[0] as HTMLHeadElement;
          tittle.innerText = this.currentPageData.pageName;
        }
      })
      .catch((error) => {
        console.log("error is", error);
      });
  }


  searchHandler(req:any) {
    if(req == "country"){
      this.route.navigate(["/winelocations"], {
        queryParams: { country: this.currentPageData.countryId },
      });
    }else if(req == "state"){
      this.route.navigate(["/winelocations"], {
        queryParams: { country: this.currentPageData.countryId, state: this.currentPageData.stateId },
      });
    }else if(req == "city"){
      this.route.navigate(["/winelocations"], {
        queryParams: { country: this.currentPageData.countryId, state: this.currentPageData.stateId, city: this.currentPageData.cityId },
      });
    }
  }

  ngAfterViewInit() {
     this.route.events.pipe(  
            filter(event => event instanceof NavigationEnd),  
           ).subscribe(() =>
            this.titleService.setTitle("BookVino"));
  }
}
