import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { apiRoutes } from "../services/routes/apiRouets";

@Component({
  selector: "app-direct-booking",
  templateUrl: "./direct-booking.component.html",
  styleUrls: ["./direct-booking.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DirectBookingComponent implements OnInit {
  title = "angularbootstrap";
  inputnumber = 0;
  bookingForm: FormGroup;
  isSubmited: boolean;
  foo = [];
  model: NgbDate;
  backButton: number = 1;
  businessDetails: any;
  businessId: any;
  getSlots: any;
  isDisabled: any;
  disabledDates: any = {
    disable: [],
    disabledDates: [],
  };
  slotId: any;
  total: any = 0;
  persons: any;
  selectedDate: any;
  adultPrice: any = 0;
  childPrice: any = 0;
  IsPending: boolean = false;
  backgroundImage = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _router: Router,
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private calendar: NgbCalendar,
    private parserFormatter: NgbDateParserFormatter,
    private config: NgbDatepickerConfig
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate() + 1,
    };
  }

  ngOnInit(): void {
    let back = document.getElementsByClassName(
      "backbutton"
    )[0] as HTMLLinkElement;
    if (back) {
      if(this.backButton == 1){
        back.style.display = "none";
      }
    }
    this.persons = localStorage.getItem("totalPerson");
    console.log("persons", this.persons);

    let origin = window.location.href;
    if (!origin.includes("Id")) {
      window.location.href = window.location.origin;
    }
    this.businessId = origin.split("=")[1];

    this.bookingForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2}$"),
        ],
      ],
      phoneNumber: ["", [Validators.required]],
      adult: ["", [Validators.required]],
      children: ["", [Validators.required]],
      totalPrice: ["", [Validators.required]],
    });

    for (var i = 0; i <= 100; i++) {
      this.foo.push(i);
    }

    this.getBusinessById(this.businessId);
  }

  ngAfterViewInit() {
    let btn1 = document.getElementsByClassName(
      "ngb-dp-arrow-btn"
    )[0] as HTMLLinkElement;
    let btn2 = document.getElementsByClassName(
      "ngb-dp-arrow-btn"
    )[1] as HTMLLinkElement;
    let btn3 = document.getElementsByClassName("ngb-dp-navigation-select")[0]
      .children[0] as HTMLLinkElement;
    let btn4 = document.getElementsByClassName("ngb-dp-navigation-select")[0]
      .children[1] as HTMLLinkElement;
    btn1.addEventListener("click", this.getSlotsByMonth.bind(this));
    btn2.addEventListener("click", this.getSlotsByMonth.bind(this));
    btn3.addEventListener("click", this.getSlotsByMonth.bind(this));
    btn4.addEventListener("click", this.getSlotsByMonth.bind(this));
    this.getSlotsByMonth();

    
  }

  async getSlotsByMonth() {
    let monthHtml = document.getElementsByClassName(
      "ngb-dp-navigation-select"
    )[0].children[0] as HTMLLIElement;
    let yearHtml = document.getElementsByClassName(
      "ngb-dp-navigation-select"
    )[0].children[1] as HTMLLIElement;
    let year = yearHtml.value;
    let month = monthHtml.value;

    let requestObj = {
      businessId: this.businessId,
      month: month,
      year: year,
      persons: this.inputnumber,
    };

    localStorage.setItem("totalPerson", JSON.stringify(this.inputnumber));
  }

  async getBusinessById(Id: any) {
    this.spinner.show();

    let obj = {
      businessId: Id,
    };

    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetBusinessById, obj)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.businessDetails = res.data;

          res.data.businessImages.forEach((element: any) => {
            let img = environment.API_URL + "" + element.image;
            this.backgroundImage.push(img);
          });

          this.spinner.hide();
          if (res.data.isPublic == false) {
            window.location.href = window.location.origin;
          }
        }
      })
      .catch((error) => {
        console.log("error is", error);
        this.spinner.hide();
      });
  }

  plus() {
    this.inputnumber;
    this.inputnumber = parseInt(this.inputnumber.toString()) + 1;
  }

  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = parseInt(this.inputnumber.toString()) - 1;
    }
  }

  gotobookings() {
    let back = document.getElementsByClassName(
      "backbutton"
    )[0] as HTMLLinkElement;
    if (back) {
      back.style.display = "none";
    }
    if (
      parseInt(this.inputnumber.toString()) < 0 ||
      parseInt(this.inputnumber.toString()) == 0
    ) {
      this.inputnumber = 1;
      alert("Please add valid value");
      return;
    }

    this.backButton = this.backButton + 1;
    let info = document.getElementsByClassName("info")[0] as HTMLLinkElement;
    let calender = document.getElementsByClassName(
      "calender"
    )[0] as HTMLLinkElement;
    let bookingForm = document.getElementsByClassName(
      "bookingForm"
    )[0] as HTMLLinkElement;

    if (info) {
      info.style.display = "none";
    }
    if (calender) {
      calender.style.display = "block";
      this.getSlotsByMonth();
    }
    if (bookingForm) {
      bookingForm.style.display = "none";
    }
  }

  async getSlotsByDate(date: any) {
    this.selectedDate = new Date(
      this.parserFormatter.format(date)
    ).toISOString();
    let paging = {
      businessId: this.businessId,
      date: new Date(this.parserFormatter.format(date)).toISOString(),
    };
    this.spinner.show();
    await this.http
      .post<any>(environment.API_URL + apiRoutes.CheckAvailability, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.getSlots = res.data.slots;
          if (res.data.slots.length <= 1) {
            this.toastr.info(
              "There are no time available for selected date",
              "Info",
              {
                timeOut: 3000,
              }
            );
          } else {
            let slotsHtml = document.getElementsByClassName(
              "currentslots"
            )[0] as HTMLLinkElement;
            slotsHtml.style.display = "block";
            // let calendarHtml = document.getElementsByClassName(
            //   "bg-primary text-white"
            // )[0] as HTMLLinkElement;
            // if (!calendarHtml.classList.contains("bg-primary")) {
            //   calendarHtml.classList.add("bg-primary");
            // }
          }
          this.spinner.hide();
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
  }

  bookNow() {
    let slotsHtml = document.getElementsByClassName(
      "slotsDropdown"
    )[0] as HTMLLIElement;
    this.slotId = slotsHtml.value;
    if (this.slotId == 0) {
      alert("Please select a slot");
      return;
    }
    this.backButton = this.backButton + 1;
    let info = document.getElementsByClassName("info")[0] as HTMLLinkElement;
    let calender = document.getElementsByClassName(
      "calender"
    )[0] as HTMLLinkElement;
    let bookingForm = document.getElementsByClassName(
      "bookingForm"
    )[0] as HTMLLinkElement;

    if (info) {
      info.style.display = "none";
    }
    if (calender) {
      calender.style.display = "none";
    }
    if (bookingForm) {
      bookingForm.style.display = "block";
    }
  }

  onSubmitHandler() {
    this.persons = localStorage.getItem("totalPerson");
    console.log(
      "persons",
      this.persons,
      parseInt(this.bookingForm.value.adult) +
        parseInt(this.bookingForm.value.children)
    );
    this.spinner.show();

    if (
      this.persons <
      parseInt(this.bookingForm.value.adult) +
        parseInt(this.bookingForm.value.children)
    ) {
      this.toastr.warning(
        "Adults and Childrens not more then total Persons",
        "Warning",
        {
          timeOut: 5000,
        }
      );
      this.isSubmited = true;
      this.spinner.hide();
      return;
    }

    if (this.bookingForm.invalid) {
      this.isSubmited = true;
      this.spinner.hide();
      return;
    }

    localStorage.setItem("businessId", this.businessId);

    let formObj = {
      isPending: false,
      adult: this.bookingForm.value.adult,
      bookingDate: this.selectedDate,
      businessId: parseInt(this.businessId),
      children: this.bookingForm.value.children,
      email: this.bookingForm.value.email,
      firstName: this.bookingForm.value.firstName,
      lastName: this.bookingForm.value.lastName,
      phoneNumber: +this.bookingForm.value.phoneNumber,
      timeSlotId: parseInt(this.slotId),
      totalPersons: this.inputnumber,
      totalPrice: this.total,
      isExpress: true,
    };

    this.http
      .post<any>(environment.API_URL + apiRoutes.CreateBooking, formObj)
      .toPromise()
      .then(async (res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();

          this.bookingForm.reset();
          this.spinner.hide();
          this._router.navigate(["/confirmationpage"]);
        }
      })
      .catch(async (error) => {
        console.log("err", error);
        this.spinner.hide();
      });
  }

  setAdultTotal(data: any) {
    let currentSlotData = this.getSlots.filter(
      (x: any) => x.id == this.slotId
    )[0];
    this.adultPrice = currentSlotData.adultRate * data;
    if (this.childPrice > 0) {
      this.total = this.adultPrice + this.childPrice;
    } else {
      this.total = this.adultPrice;
    }
    this.bookingForm.patchValue({
      totalPrice: this.total,
    });
  }

  setChildTotal(data: any) {
    let currentSlotData = this.getSlots.filter(
      (x: any) => x.id == this.slotId
    )[0];
    this.childPrice = currentSlotData.childRate * data;
    if (this.adultPrice > 0) {
      this.total = this.childPrice + this.adultPrice;
    } else {
      this.total = this.childPrice;
    }
    this.bookingForm.patchValue({
      totalPrice: this.total,
    });
  }

  checkIfNumber(inputtxt: any) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return;
    } else {
      let txt = inputtxt.slice(0, -1);
      this.bookingForm.controls.phoneNumber.patchValue(txt);
      alert("Please Enter Number Only");
    }
  }

  switchView() {
    if (this.backButton == 2) {
      let back = document.getElementsByClassName(
        "backbutton"
      )[0] as HTMLLinkElement;
      back.style.display = "none";
      if (this.getSlots != null) {
        this.slotId = 0;
        this.getSlots = null;
        let calendarHtml = document.getElementsByClassName(
          "bg-primary text-white"
        )[0] as HTMLLinkElement;
        calendarHtml.classList.remove("bg-primary");
        let slotsHtml = document.getElementsByClassName(
          "currentslots"
        )[0] as HTMLLinkElement;
        slotsHtml.style.display = "none";
      }
      this.backButton = this.backButton - 1;
      let info = document.getElementsByClassName("info")[0] as HTMLLinkElement;
      let calender = document.getElementsByClassName(
        "calender"
      )[0] as HTMLLinkElement;
      let bookingForm = document.getElementsByClassName(
        "bookingForm"
      )[0] as HTMLLinkElement;

      if (info) {
        info.style.display = "block";
        this.slotId = 0;
      }
      if (calender) {
        calender.style.display = "none";
      }
      if (bookingForm) {
        bookingForm.style.display = "none";
      }
    }

    if (this.backButton == 3) {
      this.bookingForm.controls.firstName.patchValue(null);
      this.bookingForm.controls.lastName.patchValue(null);
      this.bookingForm.controls.email.patchValue(null);
      this.bookingForm.controls.phoneNumber.patchValue(null);
      this.bookingForm.controls.adult.patchValue(null);
      this.bookingForm.controls.children.patchValue(null);
      this.bookingForm.controls.totalPrice.patchValue(null);
      this.total = 0;

      this.backButton = this.backButton - 1;
      let info = document.getElementsByClassName("info")[0] as HTMLLinkElement;
      let calender = document.getElementsByClassName(
        "calender"
      )[0] as HTMLLinkElement;
      let bookingForm = document.getElementsByClassName(
        "bookingForm"
      )[0] as HTMLLinkElement;

      if (info) {
        info.style.display = "none";
      }
      if (calender) {
        calender.style.display = "block";
      }
      if (bookingForm) {
        bookingForm.style.display = "none";
      }
    }
  }
}
