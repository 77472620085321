import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./Footer.component.html",
  styleUrls: ["./Footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  CookieAccepted = sessionStorage.getItem('CookieAccepted');
  isShow = this.CookieAccepted == 'true' ? false: true;
  IsLoggedIn: boolean;  

  ngOnInit() {
    this.checkIfLoggedIn();
  }

  toggleDisplay() {
        this.isShow = !this.isShow;
        sessionStorage.setItem("CookieAccepted", "true");          
       
  }

  checkIfLoggedIn() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.IsLoggedIn = currentUser != null ? false : true;
  }
}
