import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminMenuItems } from './admin-menu-items';
import {AuthService} from '../../services/authGuard/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiRoutes } from 'src/app/services/routes/apiRouets';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-header',
  templateUrl: './AdminHeader.component.html',
  styleUrls: ['./AdminHeader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminHeaderComponent implements OnInit {
  loginDetails: any;
  userProfileData = null as any;
  imageSrc = './Uploads/2f053936-3ac2-4b35-bac6-c2b6583d11c9.png'// 'assets/images/thumb-3.jpg' 
  userImage = '';
  constructor(public adminMenuItems: AdminMenuItems,
  private authService: AuthService,
  public http: HttpClient,
  private common: CommonService,
  private router: Router) {
    this.common.profileUpdate.subscribe(res => {
      this.getUserProfile();
    });
    this.getUserProfile();
  }

   ngOnInit(){}

   logout() {
    this.authService.logout();
   }

   profileRouter (){
    this.router.navigate(['/admin/profile']);
   }

   getUserProfile() {
    this.loginDetails = JSON.parse(localStorage.getItem('currentUser'));
    if (!this.loginDetails) {
      return;
    }

    this.http.get<any>(environment.API_URL + apiRoutes.GetUserProfile).toPromise().then((res: any) => {
      if (res.statusCode === 200) {
        this.userProfileData = res.data;
        this.userImage = res.data.profileImage ? environment.API_URL + res.data.profileImage : environment.API_URL + this.imageSrc;
      }
    }).catch(error => {
      console.log('error is', error);
    });
  }
}
