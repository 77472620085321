import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private Language = new BehaviorSubject('');
  languageChange = this.Language.asObservable();

  private profileChange = new Subject();
  profileUpdate = this.profileChange.asObservable();

  constructor(public translate: TranslateService) { }

  changeLanguage(lang: any) {
    this.Language.next(lang);
  }

  refreshProfile() {
    this.profileChange.next();
  }
}
