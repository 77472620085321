<div class="db-admin">
  <div class="dashboard-nav">
    <div class="dashboard-nav-inner">
      <ul>
        <p
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
          style="
            margin-left: 30px;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 0px !important;
          "
        >
          Main
        </p>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
          class="active"
        >
          <a [routerLink]="['/admin/dashboard']"
            ><i class="fa fa-tachometer"></i> Dashboard</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/messages']"
            ><i class="fa fa-inbox"></i> Messages
            <!-- <span class="nav-tag messages">2</span> -->
          </a>
        </li>

        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/bookings']"
            ><i class="fa fa-calendar" id="myOrders2"></i> My Bookings</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 1 && userType.data.userType !== 3"
        >
          <a [routerLink]="['/admin/bookings']"
            ><i class="fa fa-calendar" id="myOrders2"></i> Bookings</a
          >
        </li>
        <!-- <li><a [routerLink]="['/admin/bookmarks']"><i class="fa fa-bookmark"></i> Create Admin</a></li> -->
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/create-admin']"
            ><i class="fa fa-user-plus"></i> Create Admin</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/customer']"
            ><i class="fa fa-users"></i> Customers</a
          >
        </li>
        <!-- <li *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"><a
                                    [routerLink]="['/admin/editlanguage']"><i class="fa fa-users"></i> Edit language</a>
                        </li> -->
        <!-- <li *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"><a
                                    [routerLink]="['/admin/editcustomer']"><i class="fa fa-users"></i>Edit Customers</a>
                        </li> -->
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/country']"
            ><i class="fa fa-flag"></i> Create Country</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/state']"
            ><i class="fa fa-map"></i> Create Region</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/city']"
            ><i class="fa fa-map-marker"></i> Create Sub-Region</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/amenities']"
            ><i class="fa fa-list"></i> Add Amenities</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/language']"
            ><i class="fa fa-language"></i> Add Language</a
          >
        </li>
        <li *ngIf="userType.data.userType !== 3">
          <a [routerLink]="['/admin/reports']"
            ><i class="fa fa-file"></i> Winery Reports</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/userreports']"
            ><i class="fa fa-user"></i> User Reports</a
          >
        </li>
      </ul>
      <ul>
        <p
          *ngIf="userType.data.userType !== 3"
          style="
            margin-left: 30px;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 0px !important;
          "
        >
          Listings
        </p>

        <li *ngIf="userType.data.userType !== 3">
          <a [routerLink]="['/admin/list']"
            ><i class="fa fa-calendar-check-o"></i> My wineries</a
          >
        </li>
        <!-- <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/reviews']"
            ><i class="fa fa-star"></i> Reviews</a
          >
        </li> -->

        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/add-list']"
            ><i class="fa fa-calendar-plus-o"></i> Add winerie</a
          >
        </li>

        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/addpagebuilder']"
            ><i class="fa fa-calendar-plus-o"></i> Add Page Builder</a
          >
        </li>
        <li
          *ngIf="userType.data.userType !== 3 && userType.data.userType !== 2"
        >
          <a [routerLink]="['/admin/listpagebuilder']"
            ><i class="fa fa-calendar-plus-o"></i> List Page Builder</a
          >
        </li>
      </ul>
      <ul>
        <p
          style="
            margin-left: 30px;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 0px !important;
          "
        >
          Account
        </p>
        <li>
          <a [routerLink]="['/admin/profile']">
            <i class="fa fa-user" id="myProfile"></i> My Profile</a
          >
        </li>

        <li *ngIf="userType.data.userType !== 1">
          <a [routerLink]="['/admin/mybookings']"
            ><i class="fa fa-calendar" id="myOrders"></i> Personal Orders</a
          >
        </li>

        <li>
          <a (click)="logout()" [routerLink]="['']"
            ><i class="fa fa-sign-out" (click)="logout()"></i> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
<a class="db-trigger" (click)="toggleMenu()">
  <i class="fa fa-reorder"></i>
</a>
