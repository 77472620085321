<div>
  <div class="container">
    <div style="color: #000; margin-top: 200px" class="pageTittle">
      <h2>{{ currentPageData?.pageName }}</h2>
      <i  class="fa fa-map-marker"></i>
      <a
        (click)="searchHandler('country')"
        style="
          padding-left: 12px !important;
          color: #6b7280;
          cursor: pointer !important;
        "
        >{{ currentPageData?.countryName }}
      </a>
      <a
        (click)="searchHandler('state')"
        style="
          padding-left: 12px !important;
          color: #6b7280;
          cursor: pointer !important;
        "
        >/ {{ currentPageData?.stateName }}</a
      >
      <a
        (click)="searchHandler('city')"
        style="
          padding-left: 12px !important;
          color: #6b7280;
          cursor: pointer !important;
        "
        >/ {{ currentPageData?.cityName }}</a
      >
    </div>
  </div>

  <div class="main-image container">
    <img
      class="mainImageModel"
      src=""
      alt="about"
      width="100%"
      style="
        max-height: 600px;
        height: 600px;
        object-fit: cover;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: 10px;
      "
    />
    <div>
      <pre style="text-align: center" class="centered">
     {{ currentPageData?.imageHeading }}
  </pre
      >
    </div>
    <div>
      <pre style="text-align: center" class="subcentered">
     {{ currentPageData?.imageSubHeading }}
  </pre
      >
    </div>

  </div>

  <div class="container about-container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div
          class="about-header"
          style="padding-bottom: 2rem; text-align: center"
        >
          <h1>{{ currentPageData?.imageSubHeading }}</h1>
        </div>
        <div class="main-container">
          <p
            class="desc"
            style="font-size: 24px; color: #686666; font-weight: 300"
          >
            {{ currentPageData?.bodyDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-bottom: 40px;"  class="main container Card col-lg-12 col">
    <div class="row col-lg-12 col-md-12">
     <div class="winemakings col-sm-12 col-md-4 col-lg-4 " *ngFor="let place of currentPageData.businesses">
        <div class="category-box-container text-center mt-5" >
           <div class="listing-item-container  ">
             <div class="listing-item text-center ">
               <div class="mostviewed-bg"
                style="background-position: center !important; background-size: cover !important; background: url({{currentUrl+place.image}}); cursor: pointer;">
                <div class="listing-item-content">
                  <div class="list-logo">
                  </div>
                  <a>
                    <h3>{{place.businessName}}</h3>
                  </a>
                  <p class="mb-0"> <small  style=" display: -webkit-box;
                  max-width: 100%;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  " >{{place.details}}...</small></p>
                  <div class="btnLeft">
                    <a
                      ><span
                        class="btn">Book Now</span></a>
                    <span><i style="margin-left: 25px;" class="fa fa-map-marker"></i><strong
                        class="iconText mb-2">{{currentPageData?.stateName}}</strong> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
