import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { filter } from "rxjs/operators";
import { apiRoutes } from "../../../app/services/routes/apiRouets";

import { environment } from "src/environments/environment";
import { logInResponse } from "../../../app/services/responseMessages/response";
import { CommonService } from "src/app/services/common/common.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/authGuard/auth.service";
import { windowWhen } from "rxjs-compat/operator/windowWhen";
@Component({
  selector: "app-header",
  templateUrl: "./Header.component.html",
  styleUrls: ["./Header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<any>;
  private _router: Subscription;
  url: string;
  loginDetails: any;
  userProfileData: any;
  userImage: any;
  imageSrc: string = "assets/images/user-profile.png";

  isFixedClass: boolean = false;
  lang = "en";

  bookingOptions: string;

  constructor(
    private route: Router,
    private http: HttpClient,
    private router: Router,
    private common: CommonService,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.common.languageChange.subscribe((languageChange) => {
      this.lang = languageChange;
    });
  }

  ngOnInit() {
    this.loginDetails = JSON.parse(localStorage.getItem("currentUser"));
    if (this.loginDetails.data != null) {
      this.getUserProfile();
    }
    this.bookingOptions =
      this.loginDetails.data.userType == 1 ||  this.loginDetails.data.userType == 2 ? "Orders" : "Bookings";
  }

  isFixedHeader() {
    if (
      this.url === "/listing/wine-tasting/grid" ||
      this.url === "/listing/wine-tasting/list"
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewInit() {}

  getUserProfile() {
    this.http
      .get<any>(environment.API_URL + apiRoutes.GetUserProfile)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.userProfileData = res.data;
          this.userImage = res.data.profileImage
            ? environment.API_URL + res.data.profileImage
            : this.imageSrc;
        }
      })
      .catch((error) => {
        console.log("error is", error);
      });
  }

  logIn() {
    if (
      localStorage.getItem("currentUser") == null ||
      localStorage.getItem("currentUser").length == 0
    ) {
      this.route.navigate(["session/login"]);
    } else {
      // this.route.navigate(["/admin"]);
    }
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage(type) {
    switch (type) {
      case 1:
        this.common.changeLanguage("da");
        this.translate.use("en");
        localStorage.setItem("langInfo", "en");
        localStorage.setItem("baseURL", "/");
        break;
      case 2:
        // this.common.changeLanguage("da");
        // this.translate.use("da");
        // localStorage.setItem("langInfo", "da");
        break;
      case 3:
        this.common.changeLanguage("en");
        this.translate.use("da");
        localStorage.setItem("langInfo", "da");
        localStorage.setItem("baseURL", "/da/");
        break;
    }
    let currentURL = window.location.pathname;
    if (type == 1) {
      currentURL = window.location.pathname.replace("/da", "");
      this.router.navigate([currentURL]);
    } else {
      currentURL = "/da" + currentURL;
      this.router.navigate([currentURL]);
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
}
