import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { apiRoutes } from 'src/app/services/routes/apiRouets';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-winelocations',
  templateUrl: './winelocations.component.html',
  styleUrls: ['./winelocations.component.css']
})
export class WinelocationsComponent implements OnInit {
  businessDetails: any;
  total: any;
  allCountries: any;
  allCatagories: any;
  userType: any;
  allBussiness: any;
  allPages: any;
  Obj: any;
  countryId: any;
  pagesize: any = 9;
  cityId: any;
  stateId: any;
  allStates: any;
  allCity: any;
  currentLang: boolean;
  baseURL: String = "";
  countryValue: string = "";
  stateValue: string = "";
  cityValue: string = "";
  public tittle: string = ''

  constructor(public route: ActivatedRoute,
    public http: HttpClient,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleService: Title) { }
    

  async ngOnInit(): Promise<void> {
    this.getAllCountries();

    this.userType = JSON.parse(localStorage.getItem("currentUser"));

    if (localStorage.getItem("baseURL") != null) {
      this.baseURL = localStorage.getItem("baseURL");
    }

    this.Obj = {
      country: this.route.snapshot.queryParamMap.get("country"),
      state: this.route.snapshot.queryParamMap.get("state"),
      city: this.route.snapshot.queryParamMap.get("city"),
    };

    this.countryValue = this.Obj.country;
    if (this.countryValue != null) {
      await this.setCountry(this.countryValue);
    }
    this.stateValue = this.Obj.state;
    if (this.stateValue != null) {
      await this.setRegion(this.stateValue);
    }

    this.cityValue = this.Obj.city;
    if (this.cityValue != null) {
      await this.setSubRegion(this.cityValue);
    }

    this.getAllPages();
  
  }
  async getAllPages() {
    let paging = {
      page: 1,
      pageSize: this.pagesize,
      isActive: false,
      // loggedInUserId: this.userType.data.userType == 1 ? null : this.userType.data.userId,
      // businessOwnerId:
      //   this.userType.data.userType == 1 ? null : this.userType.data.userId,
     
    };
    this.spinner.show();
    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetAllPages, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();
          this.allPages = res.data;
          if(res.total == res.data.length){
            let btn = document.getElementsByClassName('loadmorebtn')[0] as HTMLButtonElement;
            btn.style.display = "none";
          }
          this.allPages.forEach(function (item: any) {
            item.backgroundImage =
              item.backgroundImage != null
                ? environment.API_URL + item.backgroundImage.substring(2)
                : item.backgroundImage;
          });
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
  }

  async loadMorePages(){
    this.pagesize = this.pagesize + 9;
    await this.getAllPages();
  }
 
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
        return this.getChild(activatedRoute.firstChild);  
    } else {  
        return activatedRoute;  
    }
}

  async searchHandler() {
    // let formObj = {
    //   country: this.countryId,
    //   state: this.stateId,
    //   city: this.cityId,
    // };

    let paging = {
      page: 1,
      pageSize: 200,
      countryId: this.countryId,
      stateId: this.stateId == "null" ? null : this.stateId,
      cityId: this.cityId == "null" ? null : this.cityId,
      isActive: true,
    };

    this.spinner.show();
    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetAllPages, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();
          this.allPages = res.data;
          this.allPages.forEach(function (item: any) {
            item.backgroundImage =
              item.backgroundImage != null
                ? environment.API_URL + item.backgroundImage.substring(2)
                : item.backgroundImage;
          });
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
   
  }

  async getAllCountries() {
    let paging = {
      page: 1,
      pageSize: 200,
      isWineryPage : false
    };
    this.spinner.show();
    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetAllCountries, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();
          this.allCountries = res.data;
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
  }

  async setCountry(location: string) {
    this.stateId = null;
    this.cityId = null;
    this.countryId = location;
    let paging = {
      countryId: location,
      page: 1,
      pageSize: 200,
    };
    this.spinner.show();

    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetAllStates, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();
          this.allStates = res.data;
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
  }

  async setRegion(region: string) {
    this.stateId = region;
    let paging = {
      stateId: region,
      page: 1,
      pageSize: 200,
    };
    this.spinner.show();
    await this.http
      .post<any>(environment.API_URL + apiRoutes.GetAllCities, paging)
      .toPromise()
      .then((res: any) => {
        if (res.statusCode === 200) {
          this.spinner.hide();
          this.allCity = res.data;
        }
      })
      .catch((error) => {
        this.spinner.hide();
        console.log("error is", error);
      });
  }

  async setSubRegion(subRegion: string) {
    this.cityId = subRegion;
  }
  
}


